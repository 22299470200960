<template>
  <el-main class="rh-table">
    <h1><i class="el-icon-user" /> Utilisateurs</h1>
    <app-table
      tablenom="users"
      :cols="colonnes"
      :items="users"
      :loadstatus="usersLoadStatus"
      :deletestatus="userDeleteStatus"
      :formroutes="userFormRoutes"
      :actions="userActions"
    />
  </el-main>
</template>

<script>
import AppTable from "@/components/rh/ui/table/AppTable";
export default {
  components: { "app-table": AppTable },
  name: "UsersView",
  data() {
    return {
      userFormRoutes: {
        edit: { route: "useredit", params: { iduser: "id" } },
        create: { route: "usercreate" },
      },
    };
  },
  computed: {
    users() {
      return this.$store.state.user.users;
    },
    usersLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.user.usersLoadStatus;
    },
    userDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.user.userDeleteStatus;
    },
    colonnes() {
      return this.$store.state.user.colonnes;
    },
    userActions() {
      return this.$store.state.user.actions;
    },
  },
};
</script>
